<template>
  <modal-pro></modal-pro>
  <section class="section-full banner">
    <div class="box">
      <img src="./../../assets/banner-va.png" alt="banner-va"  class="banner-img"  />
      <div class="banner-sep"></div>
      <h1 v-html="$t('va.title1')"></h1>
    </div>
    <scroll-down></scroll-down>
  </section>
  <product v-for="(item, idx) in products" :key="idx" :product="item"></product>
  <product-range product="VA"></product-range>
</template>

<script>
import ScrollDown from "./../../components/ScrollDown.vue";
import ProductRange from "./../../components/ProductsRange.vue";
import Product from "./../../components/Product.vue";
import ModalPro from "./../../components/ModalPro.vue";

export default {
  name: "VA",
  components : { ScrollDown, ProductRange, Product, ModalPro},
  computed : {
    products() {
      return [
        {
          img : [
            require("@/assets/products/ballon.png")
          ],
          bg : require("@/assets/products/cedar_bg.jpg"),
          name : this.$t("kypho.title"),
          special :  false,
          video : "https://youtu.be/nkczODf5vFg",
          bullets : [
            this.$t("kypho.bullet1"),
            this.$t("kypho.bullet2"),
            this.$t("kypho.bullet3")
          ]
        },
        {
          img : [
            require("@/assets/products/cement.png")
          ],
          bg : require("@/assets/products/oak_bg.jpg"),
          name : this.$t("cement.title"),
          special :  false,
          video : null,
          bullets : [
            this.$t("cement.bullet1"),
            this.$t("cement.bullet2"),
            this.$t("cement.bullet3"),
            this.$t("cement.bullet4"),
            this.$t("cement.bullet5"),
            this.$t("cement.bullet6")
          ]
        },
        {
          img : [
            require("@/assets/products/sycamore.png"),
          ],
          bg : require("@/assets/products/walnut_bg.jpg"),
          name : "<span class='colored'>S</span>ycamore",
          special :  true,
          video : null,
          bullets : [
            this.$t("sycamore.bullet1"),
            this.$t("sycamore.bullet2"),
            this.$t("sycamore.bullet3"),
            this.$t("sycamore.bullet4"),
          ]
        },
      ]
    }
  }
}
</script>

<style scoped lang="scss">

h1 {
  color:#fff;
  font-size:30px;
  font-weight:400;
  text-align: center;
  line-height:1.5em;
  margin:0;
}

@media screen and (max-width:768px) {
  h1 {
    font-size:20px;
  }
}
</style>